<script setup>
// this is temporary, only used for onboarding users who signed up on prelaunch page. after all are in, this should be removed

import {ref, computed, onMounted} from "vue"
import {useUserStore} from "../stores/user"
import {storeToRefs} from "pinia"
import {OButton} from "@jirizavadil/framework.one/ui"
import {useRoute, useRouter} from "vue-router"
import {auth, firestore, functions} from "../firebase/index.js"
import {getDoc, doc} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import IconGoogle from "@/assets/img/icons/icon-google.svg"
import {
  GoogleAuthProvider,
  signInWithPopup,
  // signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth"
import sink from "@/sink/index.js"

const usersOnboardFn = httpsCallable(functions, "calls-usersOnboard")

const emit = defineEmits(["close"])

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const {user} = storeToRefs(userStore)

const passwordField = ref(null)
const stage = ref(false)
const onboardingCode = ref(route.query.onboard)
const onboardingDone = ref(route.query.onboarded)
const userId = ref(false)
const email = ref(null)
const password = ref(null)
const setPasswordProgress = ref(false)

async function getUser() {
  if (!onboardingCode.value) return
  console.log("getUser()")

  // get onboarding code doc to get user id
  const onboardingCodeRef = doc(firestore, `userOnboardingCodes/${onboardingCode.value}`)
  const onboardingCodeDoc = await getDoc(onboardingCodeRef)
  if (!onboardingCodeDoc.exists()) return

  const onboardingCodeData = onboardingCodeDoc.data()
  userId.value = onboardingCodeData.userId
  email.value = onboardingCodeData.email
  stage.value = "createPassword"
}

async function markCodeOpened() {
  if (!onboardingCode.value) return
  console.log("markCodeOpened()")

  // mark onboarding code as opened
  await usersOnboardFn({
    action: "linkOpened",
    userOnboardingCode: onboardingCode.value,
  })
}

async function onClickSetPassword() {
  if (!password.value) return
  setPasswordProgress.value = true
  console.log("onClickSetPassword()")

  await usersOnboardFn({
    action: "setPassword",
    userOnboardingCode: onboardingCode.value,
    password: password.value,
  })

  await signInWithPassword()
  setPasswordProgress.value = false
}

async function signInWithPassword() {
  const response = await signInWithEmailAndPassword(auth, email.value, password.value).catch(
    (e) => {
      console.error(e.code)
    }
  )
  router.replace({query: {onboard: onboardingCode.value, onboarded: true}})
  stage.value = "signedIn"
}

async function signInWithGoogle() {
  console.log("signInWithGoogle()")
  const provider = new GoogleAuthProvider()

  // redirect will probably only work only after it runs on main domain and the domain is same as FIREBASE_AUTH_DOMAIN (eg. app.atlaseek.com)
  // if (isMobile(window.navigator).any) {
  // signInWithRedirect(auth, provider)
  // } else {
  const {user} = await signInWithPopup(auth, provider)
  // }
  console.log({user})

  if (user.email == email.value) {
    await usersOnboardFn({
      action: "markOnboarded",
      userOnboardingCode: onboardingCode.value,
    })
    router.replace({query: {onboard: onboardingCode.value, onboarded: true}})
    stage.value = "signedIn"
  }
}

function onClickStartExploring() {
  router.replace({query: {}})
  sink.event("clicked_view_discover_onboarding_success_explore")
  emit("close")
}

function onClickCheckOutRoadmap() {
  router.push({name: "RoadmapView"})
  sink.event("clicked_view_discover_onboarding_success_roadmap")
  emit("close")
}

onMounted(() => {
  if (userStore.userLoaded || onboardingDone.value) {
    stage.value = "signedIn"
  } else {
    getUser()
    markCodeOpened()
    sink.event("view_discover_onboarding_success_shown")
  }
})
</script>

<template>
  <div class="flex justify-center p-2">
    <!-- create password -->
    <div class="" v-if="stage == 'createPassword'">
      <div class="text-center">
        <span class="block text-3xl text-brown-600 leading-8"
          >Hey there,<br />let's get you on board!</span
        >

        <span class="block mt-4">{{ email }}</span>
      </div>

      <span class="mt-8 block text-center text-xl text-brown-600 leading-3">sign in</span>

      <!-- providers -->
      <div class="mt-8 flex flex-center">
        <div
          class="w-10 h-10 bg-brown-400 hover:bg-brown-500 cursor-pointer transition-all duration-300 ease-in-out rounded-full flex flex-center"
          @click="signInWithGoogle()"
        >
          <IconGoogle class="w-4 h-4" />
        </div>
      </div>

      <div class="mt-4 flex flex-row gap-2 items-center">
        <hr class="mt-1 border-sand-300 w-full" />
        <span class="text-xl text-brown-600">or</span>
        <hr class="mt-1 border-sand-300 w-full" />
      </div>

      <form class="mt-6 px-4 flex flex-col gap-2" @submit.prevent="onClickSetPassword()">
        <!-- password -->
        <input
          ref="passwordField"
          class="bg-brown-500 outline-none border-none focus:ring-0 rounded-full text-white placeholder:text-brown-400 px-4 py-2"
          type="text"
          placeholder="create password"
          autocomplete="new-password"
          v-model="password"
        />

        <div class="mt-4 flex flex-center">
          <!-- sign in/up button -->
          <OButton
            status="primaryPeach"
            label="set password"
            kind="solid"
            :progress="setPasswordProgress"
          />
        </div>
      </form>
    </div>

    <!-- signed in -->
    <div
      class="text-center flex flex-col gap-2 justify-center items-center"
      v-if="stage == 'signedIn'"
    >
      <span class="block text-3xl">That's done! 🎉</span>
      <OButton
        class="mt-10"
        status="primaryPeach"
        label="start exploring"
        @click="onClickStartExploring()"
      />
      <OButton
        class="mt-0"
        status="primary"
        kind="naked"
        label="or check out roadmap"
        @click="onClickCheckOutRoadmap()"
      />
    </div>
  </div>
</template>
