<script setup>
import MapViewCesium from "../components/map/MapViewCesium.vue"
import {RouterView} from "vue-router"
import {useAppStore} from "../stores/app"
import {useArticlesStore} from "../stores/articles"
import {useLocationsStore} from "../stores/locations.js"
import {storeToRefs} from "pinia"
import {onMounted, computed, ref, watch} from "vue"
import {useRoute, useRouter} from "vue-router"
import UniversalModal from "../components/UniversalModal.vue"
import SignUpSuccess from "../components/SignUpSuccess.vue"
import UserOnboarding from "../components/UserOnboarding.vue"
import sink from "@/sink/index.js"
import LandingHero from "../components/LandingHero.vue"
import animejs from "animejs"
import VisitAtlaseekHub from "../components/Discover/VisitAtlaseekHub.vue"

// stores
const appStore = useAppStore()
const articlesStore = useArticlesStore()
const locationsStore = useLocationsStore()

// states
const {app} = storeToRefs(appStore)
const {articles} = storeToRefs(articlesStore)
const {locations} = storeToRefs(locationsStore)

// getters
const {articlesLoaded, locationsLoaded} = storeToRefs(locationsStore)

const route = useRoute()
const router = useRouter()

const mapView = ref(null)
const sceneRenderedCount = ref(0)
const landingGradientOverlay = ref(null)
const showSignUpSuccessModal = ref(route.query.plan_upgrade || false)
const showUserOnboardingModal = ref(route.query.onboard || false)
const showGlobeImage = ref(false)
const showLandingHero = ref(false)
const showVisitAtlaseekHubModal = ref(true)
const mapFadeInTriggered = ref(false)

const pins = computed(() => {
  return locations.value.map((location) => {
    return {
      name: {
        id: location.id,
        name: location.name,
        slug: location.slug,
        country: location.country,
      },
      position: {
        lat: location.geo.latitude,
        lng: location.geo.longitude,
      },
    }
  })
})

function getArticlesAtSelectedLocation({location}) {
  // console.log("getArticlesAtSelectedLocation", location, articles.value)
  if (!articles.value) return []

  return articles.value.filter((article) => {
    // filter by country & location
    if (location.country.slug && location.slug) {
      return (
        article.countriesSlugs?.includes(location.country.slug) &&
        article.locationsSlugs?.includes(location.slug)
      )
    }
    // filter by country
    else if (location.country.slug) {
      return article.countriesSlugs?.includes(location.country.slug)
    }
    // no filter
    else {
      return true
    }
  })
}

function closeModal() {
  appStore.app.universalModal.active = false
  showSignUpSuccessModal.value = false
  showUserOnboardingModal.value = false
}

function onMapSelectedEntityChanged(entity) {
  if (!entity) return
  const {name} = entity
  const {id, name: placeName, slug, country} = name
  const {slug: slugCountry} = country

  sink.event("clicked_map_pin", {
    id,
    name: placeName,
    slug,
    country: slugCountry,
  })
}

function onMapPressedZoom(direction) {
  if (direction === "in") {
    sink.event("clicked_map_zoom_in")
  } else if (direction === "out") {
    sink.event("clicked_map_zoom_out")
  }
}

function onMaxZoom(direction) {
  // this could be used to reveal the landing again
  // if (direction === "out") {
  //   showLandingHero.value = true
  //   app.value.menu.visible = false
  //   app.value.body.fixed = false
  //   app.value.mapView.showZoomControls = false
  // }
}

function setLandingGradientOverlayOpacity() {
  const element = landingGradientOverlay.value

  const scrollPosition = window.scrollY
  const windowHeight = window.innerHeight

  // Calculate opacity based on scroll position
  const opacity = 1 - scrollPosition / windowHeight

  // Apply opacity to the element
  element.style.opacity = opacity.toString()
}

function onSceneRendered() {
  console.log("onSceneRendered()")
  sceneRenderedCount.value++
  console.log("sceneRenderedCount", sceneRenderedCount.value)
  // wait for second scene render
  if (sceneRenderedCount.value != 2) {
    // set timer to wait, because sometimes scene is not rendered -> force it
    console.log("onSceneRendered setting timer")
    setTimeout(() => {
      sceneRenderedCount.value = 2
    }, 1000)
    return
  }

  fadeInMap()
}

function fadeInSafeguard() {
  // set timer to wait, because sometimes scene is not rendered -> force it
  console.log("fadeInSafeguard setting timer")
  setTimeout(() => {
    fadeInMap()
  }, 3000)
}

function fadeInMap() {
  if (mapFadeInTriggered.value) return
  mapFadeInTriggered.value = true
  console.log("fade in map")
  const duration = route.name === "DiscoverView" && !app.value.landingPage.seen ? 10000 : 2000 // make animation long on landing page and slow on articles or article page
  animejs({
    targets: mapView.value?.$el,
    opacity: [0, 1],
    easing: "easeInOutSine",
    duration,
  })
}

// watch location change
watch(
  () => app.value.mapView.locationSelected,
  async (newValue, oldValue) => {
    console.log("new location selected", newValue, oldValue)

    // if location is selected through pin click, do nothing
    const trigger = newValue?._trigger || false
    // if (!oldValue && newValue._trigger === "mapPinClick") return

    // open drawer with location (place)
    if (newValue && newValue.id && newValue.slug && newValue.name && trigger === "mapPinClick") {
      // this is necessary if the first article is loaded through pin click
      if (!articlesLoaded) {
        await articlesStore.bind({publishedOnly: true})
      }

      const articlesAtSelectedLocation = getArticlesAtSelectedLocation({location: newValue})

      console.log({articlesAtSelectedLocation})

      // if only one article at selected location
      const selectedLocationHasOnlyOneArticle = articlesAtSelectedLocation.length === 1

      // if some article already open and is within articles at selected location (user clicks on location tag in article, but same location is within multiple articles)
      const openedArticleId = app.value.article.opened?.id
      const openedArticleWithinArticlesAtSelectedLocation =
        articlesAtSelectedLocation.findIndex((article) => article.id === openedArticleId) !== -1
      // console.log({openedArticleWithinArticlesAtSelectedLocation, openedArticleId})

      if (selectedLocationHasOnlyOneArticle || openedArticleWithinArticlesAtSelectedLocation) {
        const currentArticleSlug = route.params.articleSlug
        const newArticle = articlesAtSelectedLocation[0]
        const articleChanged = currentArticleSlug !== newArticle.slug

        // console.log({articleChanged, currentArticleSlug, newArticleSlug})

        // if same article -> rewrite url
        if (!articleChanged || openedArticleWithinArticlesAtSelectedLocation) {
          // console.log("router.replace: ArticleView")
          router.replace({
            params: {
              ...route.params,
              locationSlug: newValue.slug,
            },
          })
        }

        // if different article -> push url
        else {
          // console.log("router.push: ArticleView")
          app.value.article.opened = articlesAtSelectedLocation[0]
          router.push({
            name: "ArticleView",
            params: {
              countrySlug: newValue.country.slug,
              locationSlug: newValue.slug,
              articleSlug: newArticle.slug,
            },
            state: {
              redirectedAsSingleArticleInLocation: true,
            },
          })
        }
      }

      // if more articles at selected location -> push url
      else {
        console.log("router.push: ArticlesCountryLocationView")
        router.push({
          name: "ArticlesCountryLocationView",
          params: {countrySlug: newValue.country.slug, locationSlug: newValue.slug},
          state: {
            trigger: "mapPinClick",
          },
        })
      }
    }
    // close drawer
    else if (newValue === null) {
      router.push({name: "DiscoverView"})
    }
  }
)

// watch scroll to bottom
watch(
  () => app.value.scrollY,
  (newValue, oldValue) => {
    // only trigger on landing, otherwise it triggers everywhere
    if (showLandingHero.value) {
      if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        console.log(
          "reached bottom",
          window.scrollY,
          window.innerHeight,
          document.documentElement.scrollHeight
        )
        // showGlobeImage.value = false
        showLandingHero.value = false
        app.value.menu.visible = true
        app.value.body.fixed = true
        app.value.mapView.showZoomControls = true
        router.push({name: "ArticlesView"})
      } else {
        setLandingGradientOverlayOpacity()
      }
    }
  }
)

onMounted(() => {
  locationsStore.bind()

  // landing page
  if (route.name === "DiscoverView" && !app.value.landingPage.seen) {
    showLandingHero.value = true
    showGlobeImage.value = true

    app.value.landingPage.seen = true
  } else if (app.value.landingPage.seen) {
    showLandingHero.value = false
    onSceneRendered()
  }
  // articles / article page
  else {
    showGlobeImage.value = false
    app.value.menu.visible = true
    app.value.body.fixed = true
    app.value.mapView.showZoomControls = true
  }

  fadeInSafeguard()
})
</script>

<template>
  <!--<div class="h-screen w-screen bg-black md:flex md:flex-row-reverse">-->
  <div class="">
    <!-- modal for signup success state -->
    <UniversalModal
      :showCloseButton="false"
      :padding="true"
      @click-close="closeModal()"
      @click-backdrop="closeModal()"
      v-if="showSignUpSuccessModal"
      ><SignUpSuccess @close="closeModal()"
    /></UniversalModal>

    <!-- modal for onboarding -->
    <UniversalModal
      :showCloseButton="false"
      :padding="true"
      @click-close="false"
      @click-backdrop="false"
      v-if="showUserOnboardingModal"
      ><UserOnboarding @close="closeModal()"
    /></UniversalModal>

    <!-- modal -->
    <UniversalModal
      :showCloseButton="false"
      :padding="true"
      @click-close="showVisitAtlaseekHubModal = false"
      @click-backdrop="showVisitAtlaseekHubModal = false"
      v-if="showVisitAtlaseekHubModal"
      ><VisitAtlaseekHub @close="showVisitAtlaseekHubModal = false"
    /></UniversalModal>

    <LandingHero class="relative z-10 mb-32 px-4" v-if="showLandingHero" />
    <div
      ref="landingGradientOverlay"
      class="fixed inset-0 z-0 bg-gradient-to-t from-black to-[rgb(0,0,0)]/60"
      v-if="showLandingHero"
    ></div>

    <MapViewCesium
      ref="mapView"
      class="fixed bottom-0 right-0 top-0 h-full w-full opacity-0"
      :class="[{'-z-10': showLandingHero}]"
      :pins="pins"
      @scene-rendered="onSceneRendered"
      @selected-entity-changed="onMapSelectedEntityChanged"
      @pressed-zoom-in="onMapPressedZoom('in')"
      @pressed-zoom-out="onMapPressedZoom('out')"
      @max-zoom-in="onMaxZoom('in')"
      @max-zoom-out="onMaxZoom('out')"
      v-if="locationsLoaded"
    />

    <img
      class="fixed bottom-0 right-0 top-0 h-full w-full object-cover"
      :class="[{'-z-10': showLandingHero}]"
      src="/img/globe-with-pins.jpg"
      v-if="showGlobeImage"
    />
    <RouterView />
  </div>
</template>
