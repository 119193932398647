<script setup>
import MenuPopover from "./MenuPopover.vue"
import {ref, computed, onMounted, watch} from "vue"
import {useRoute, useRouter} from "vue-router"
import ButtonChat from "@/assets/img/buttons/button-menu-chat.svg"
import ButtonDiscover from "@/assets/img/buttons/button-menu-discover.svg"
import ButtonFeed from "@/assets/img/buttons/button-menu-feed.svg"
import ButtonMenu from "@/assets/img/buttons/button-menu-menu.svg"
import ButtonSearch from "@/assets/img/buttons/button-menu-search.svg"
import ButtonPlans from "@/assets/img/buttons/button-menu-plans.svg"
import ButtonFaq from "@/assets/img/buttons/button-menu-faq.svg"
import ButtonRoadmap from "@/assets/img/buttons/button-menu-roadmap.svg"
import {useAppStore} from "../../stores/app"
import {useUserStore} from "../../stores/user"
import {storeToRefs} from "pinia"
import {auth} from "@/firebase"
import anime from "animejs"
import sink from "@/sink/index.js"

const environment = import.meta.env.VITE_ENVIRONMENT

const route = useRoute()
const router = useRouter()

// stores
const appStore = useAppStore()
const userStore = useUserStore()

const {app} = storeToRefs(appStore)
const {user, userSignedIn} = storeToRefs(userStore)

const menuPill = ref(null)
const indicatorStyle = ref({})
const indicatorHorizontal = ref(null)
const indicatorVertical = ref(null)
const showIndicator = ref(false)

const menuItemDiscover = ref(null)
// const menuItemFeed = ref(null)
// const menuItemSearch = ref(null)
// const menuItemChat = ref(null)
const menuItemPlans = ref(null)
const menuItemFaq = ref(null)
const menuItemRoadmap = ref(null)
const menuItemMenu = ref(null)
const menuItemUser = ref(null)

const selectedRootRoute = computed(() => route.matched[0]?.name)

const profilePhotoURL = computed(() => {
  return user.value.profilePhotoURL || "/img/placeholder-menu-user-signed_in.jpg"
})

function onMenuItemClicked() {
  app.value.menu.popoverOpen = false
}

function onClick(button) {
  // app.value.menu.popoverOpen = false
  if (button === "discover") {
    // article -> articles
    if (route.name === "ArticleView") {
      router.push({name: "ArticlesView"})
      app.value.menu.popoverOpen = false
    }
    // articles -> discover
    else if (route.name === "ArticlesView") {
      router.push({name: "DiscoverView"})
      app.value.menu.popoverOpen = false
    }
    // discover (= map only) -> articles
    else if (route.name === "DiscoverView") {
      router.push({name: "ArticlesView"})
      app.value.menu.popoverOpen = false
    }
    // anything else -> discover
    else {
      router.push({name: "ArticlesView"})
      app.value.menu.popoverOpen = false
    }
  } else if (button === "menu") {
    app.value.menu.popoverOpen = !app.value.menu.popoverOpen
    sink.event("clicked_menu_main_menu", {
      signed_in: userSignedIn.value,
    })
  }
  // else if (button === "feed") {
  //   router.push({name: "FeedView"})
  //   app.value.menu.popoverOpen = false
  // } else if (button === "search") {
  //   router.push({name: "SearchView"})
  //   app.value.menu.popoverOpen = false
  // } else if (button === "chat") {
  //   router.push({name: "ChatView"})
  //   app.value.menu.popoverOpen = false
  // }
  else if (button === "plans") {
    router.push({name: "PlansView"})
    app.value.menu.popoverOpen = false
  } else if (button === "faq") {
    router.push({name: "FaqView"})
    app.value.menu.popoverOpen = false
  } else if (button === "roadmap") {
    router.push({name: "RoadmapView"})
    app.value.menu.popoverOpen = false
  }
}

function moveIndicator({view}) {
  // console.log("moveIndicator", view)
  if (view == "DiscoverView") {
    // check second level route
    // console.log(route.matched)
    showIndicator.value = false
    return
  }
  if (["ArticlesView", "ArticleView"].includes(view)) {
    view = "DiscoverView"
  }
  // const element = event.srcElement
  const elements = {
    DiscoverView: menuItemDiscover.value,
    // FeedView: menuItemFeed.value,
    // SearchView: menuItemSearch.value,
    // ChatView: menuItemChat.value,
    PlansView: menuItemPlans.value,
    FaqView: menuItemFaq.value,
    RoadmapView: menuItemRoadmap.value,
    MenuView: menuItemMenu.value,
    UserView: menuItemUser.value,
  }

  if (!elements[view]) return

  // for some reason, element is only accessible as nextElementSibling in development
  const element =
    environment == "local_dev" ? elements[view].$el.nextElementSibling : elements[view].$el

  // find element center within menu menu pill
  const menuPillRectangle = menuPill.value.getBoundingClientRect()
  const elementRectangle = element.getBoundingClientRect()

  showIndicator.value = true

  // desktop
  if (["md", "lg", "xl", "2xl"].includes(app.value.viewport.currentBreakpoint)) {
    const indicatorTop = elementRectangle.top - menuPillRectangle.top - 8
    // indicatorStyle.value.top = `${indicatorTop}px`

    anime({
      targets: indicatorVertical.value,
      top: indicatorTop,
      duration: 300,
      easing: "easeInOutQuad",
    })
  }

  // mobile
  else {
    const indicatorLeft = elementRectangle.left - menuPillRectangle.left - 8
    // indicatorStyle.value.left = `${indicatorLeft}px`

    anime({
      targets: indicatorHorizontal.value,
      left: indicatorLeft,
      duration: 300,
      easing: "easeInOutQuad",
    })
  }
}

watch(
  () => route.name,
  (newValue, oldValue) => {
    // console.log("route changed:", newValue)
    moveIndicator({view: newValue})
  }
)

watch(
  () => app.value.viewport.currentBreakpoint,
  (newValue, oldValue) => {
    moveIndicator({view: selectedRootRoute.value})
  }
)

onMounted(() => {
  moveIndicator({view: selectedRootRoute.value})
})
</script>

<template>
  <div>
    <div class="w-full max-w-sm md:h-full md:bg-brown">
      <!-- menu popover -->
      <MenuPopover
        class="mb-2 md:fixed md:bottom-4 md:left-4 md:mb-0 md:ml-16"
        @menu-item-clicked="onMenuItemClicked"
        v-if="app.menu.popoverOpen"
      />

      <!-- menu pill -->
      <div
        ref="menuPill"
        class="relative flex h-[64px] flex-row items-center justify-between overflow-hidden rounded-full bg-brown-500 px-[10px] md:h-full md:w-full md:flex-col md:justify-end md:gap-5 md:rounded-none md:pb-5"
      >
        <ButtonDiscover
          ref="menuItemDiscover"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('discover')"
        />
        <!--<ButtonFeed
          ref="menuItemFeed"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('feed')"
        />-->
        <!--<ButtonSearch
          ref="menuItemSearch"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('search')"
        />-->
        <!--<ButtonChat
          ref="menuItemChat"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('chat')"
        />-->
        <ButtonPlans
          ref="menuItemPlans"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('plans')"
        />
        <ButtonFaq
          ref="menuItemFaq"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('faq')"
        />
        <ButtonRoadmap
          ref="menuItemRoadmap"
          class="cursor-pointer hover:opacity-75 active:opacity-50"
          @click="onClick('roadmap')"
        />

        <!-- menu button -->
        <div class="cursor-pointer hover:opacity-75 active:opacity-50" @click="onClick('menu')">
          <!-- signed out -->
          <ButtonMenu ref="menuItemMenu" v-if="!userSignedIn" />
          <!-- signed in -->
          <div
            ref="menuItemUser"
            class="h-[44px] w-[44px] overflow-hidden rounded-full"
            v-if="userSignedIn"
          >
            <img class="" :src="profilePhotoURL" />
          </div>
        </div>

        <!-- indicator: horizontal -->
        <div
          ref="indicatorHorizontal"
          class="absolute -left-[1000px] bottom-0 block h-[6px] w-[60px] rounded-t-[3px] bg-peach-500 md:hidden"
          :class="[{'-bottom-10': !showIndicator}]"
        />
        <!-- indicator: vertical -->
        <div
          ref="indicatorVertical"
          class="absolute -top-[1000px] left-0 hidden h-[60px] w-[6px] rounded-r-[3px] bg-peach-500 md:block"
          :class="[{'-left-10': !showIndicator}]"
        />
      </div>
    </div>
  </div>
</template>
